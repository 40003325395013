import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { Arbeitnehmerskills } from 'app/shared/model/enumerations/arbeitnehmerskills.model';
import { License } from 'app/shared/model/enumerations/license.model';
import { MontageArbeitnehmer } from 'app/shared/model/enumerations/montage.model';
import { ISprache } from 'app/shared/model/sprache.model';
import { IZeitpunkt } from 'app/shared/model/zeitpunkt.model';
import { IArbeitsort } from 'app/shared/model/arbeitsort.model';

export interface IArbeitnehmersuche {
  id?: string;
  userID: string;
  standort: IArbeitsort | null;
  gehalt: string | null;
  auswahlBerufserfahrung: string[];
  auswahlMontage: MontageArbeitnehmer | null;
  auswahlBeruf: string | null;
  auswahlAusbildungsstand: string[];
  auswahlArbeitsverhaeltnis: Arbeitsverhaeltnis[];
  auswahlLicense?: License[];
  zeitpunkt?: IZeitpunkt | null;
  auswahlSprache?: ISprache[];
  auswahlArbeitnehmerskills?: Arbeitnehmerskills[];
  auswahlQuereinsteiger: string | null;
}

export const defaultValue: Readonly<IArbeitnehmersuche> = {
  userID: '',
  standort: null,
  gehalt: null,
  auswahlBerufserfahrung: null,
  auswahlMontage: null,
  auswahlBeruf: null,
  auswahlAusbildungsstand: [],
  auswahlArbeitsverhaeltnis: [],
  auswahlLicense: [],
  zeitpunkt: null,
  auswahlSprache: [],
  auswahlArbeitnehmerskills: [],
  auswahlQuereinsteiger: null,
};
