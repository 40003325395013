import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getMatchById } from '../../shared/reducers/match.reducer';
import { IMatch } from 'app/shared/model/match.model';
import { getAngebot } from 'app/shared/reducers/angebot.reducer';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByUserId } from 'app/shared/reducers/suchkriterien.reducer';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';

export const MatchDetailArbeitgeber = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  const match: IMatch = useAppSelector(state => state.match.entity);
  const angebot = useAppSelector(state => state.angebot.entity);
  const arbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const suchkriterien = useAppSelector(state => state.suchkriterien.entity);

  useEffect(() => {
    if (!match.id) {
      dispatch(getMatchById(id));
    }
  }, []);

  useEffect(() => {
    if (match.id) {
      dispatch(getAngebot(match.angebotId));
      dispatch(getArbeitnehmer(match.arbeitnehmerId));
      dispatch(getSuchkriterienByUserId(match.suchkriterienId));
    }
  }, [match]);

  function loaded() {
    return match.id && angebot.id && arbeitnehmer.id && suchkriterien.id;
  }

  function isFlexibel() {
    return suchkriterien.arbeitsort['flexibel'] === 'flexibel';
  }

  return (
    <div>
      {loaded() && (
        <section>
          <div className="container-std w-container">
            <div className="wrapper-950px">
              {/*Angebot:*/}
              <div id="w-node-_0ef209d8-ec40-fad7-0e3f-9b9dac516fa0-f083a74a" className="card-read-dark-grey">
                <h1 className="card-heading">Gesendetes Angebot </h1>
                <div className="card-text-blue">Angebotenes Monatsgehalt (Brutto)</div>
                <div className="text-block-215px">
                  <strong>{angebot.gehalt}€</strong>
                </div>
                <div className="card-text-blue">Darum sollten Sie bei uns arbeiten</div>
                <div className="text-block-6">{angebot.message}</div>
                <div className="padding-20"></div>

                {/*TODO was sollen diese Buttons machen?*/}
                <div className="w-layout-grid grid-cancel-send-auto">
                  <a href="#" className="call-to-action-red-max-100 w-button">
                    {' '}
                    nicht eingestellt
                  </a>
                  <a href="#" className="call-to-action-max-100 w-button">
                    eingestellt
                  </a>
                </div>

                <div className="closing-button-std">
                  <img
                    src="../../../content/images/Cross-button.png"
                    loading="lazy"
                    width="17"
                    sizes="(max-width: 767px) 16px, (max-width: 991px) 2vw, 16px"
                    alt="Ein Symbol für Schließen, dargestellt durch ein Kreuz. Das Symbol symbolisiert das Beenden, Schließen oder Verlassen einer Aktion, eines Fensters oder eines Elements."
                    srcSet="../../../content/images/Cross-button-p-500.png 500w, ../../../content/images/Cross-button.png 689w"
                  />
                </div>
              </div>

              {/*Profil: Arbeitnehmer & Suchkritierien:*/}
              <div className="card-read-dark-grey">
                <div className="accordion-toggle w-dropdown-toggle">
                  <div>
                    <strong className="card-heading">
                      Profil {arbeitnehmer.auswahlAnrede} {arbeitnehmer.vorname} {arbeitnehmer.name}, {arbeitnehmer.geburtsdatum}
                    </strong>
                  </div>
                </div>
                <div className="dropdown-accordion w-dropdown-list">
                  <div>
                    <div className="w-layout-grid grid-1x4-170px-1-1">
                      <div>
                        <div className="card-text-blue-first">Ich suche</div>
                        <div className="card-field-min-215px">{suchkriterien.auswahlArbeitsverhaeltnis}</div>
                      </div>
                    </div>
                    <div className="card-text-blue">Gewünschtes mindestgehalt (netto)</div>
                    <div className="card-field-min-215px">
                      {suchkriterien.auswahlGehalt} € pro Monat
                      <br />
                    </div>

                    <div className="card-text-blue">Meine Qualifikationen</div>
                    <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ce4-1e498197" className="div-block-19-copy">
                      <div className="div-block-18-copy">
                        <div className="div-block-18-copy">
                          <div className="w-layout-grid _1x1-copy">
                            <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ce7-1e498197" className="text-grid-white-backr-accent">
                              {suchkriterien.auswahlBeruf.auswahlBeruf}
                            </div>
                          </div>
                          <div className="horizontal-padding-3"></div>
                          <div className="w-layout-grid grid-job-details-copy">
                            <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ceb-1e498197" className="text-grid-white-backr">
                              {suchkriterien.auswahlBeruf.auswahlAusbildungsstand}
                            </div>
                            <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ced-1e498197" className="text-grid-white-backr">
                              {Berufserfahrung[suchkriterien.auswahlBeruf.auswahlBerufserfahrung]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-text-blue">Meine Eigenschaften</div>
                    <div className="w-layout-grid grid-1x4-170px">
                      {arbeitnehmer.auswahlArbeitnehmerEigenschaften?.map((eigenschaft, index) => (
                        <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d07-1e498197" className="text-grid-white-backr">
                          {eigenschaft}
                        </div>
                      ))}
                    </div>

                    {/*unternehmen benefits*/}
                    <div className="card-text-blue">Das ist mir in einem Unternehmen wichtig</div>
                    <div className="w-layout-grid grid-1x4-170px">
                      {suchkriterien.auswahlEigenschaften?.map((eigenschaft, index) => (
                        <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d1a-1e498197" className="text-grid-white-backr">
                          {eigenschaft}
                        </div>
                      ))}
                    </div>
                    <div className="card-text-blue">Führerscheine</div>
                    <div className="w-layout-grid grid-1x4-170px">
                      {suchkriterien.auswahlLicense?.map((license, index) => (
                        <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d2d-1e498197" className="text-grid-white-backr">
                          {license}
                        </div>
                      ))}
                    </div>

                    <div className="card-text-blue">Sprachen</div>
                    <div className="w-layout-grid">
                      {suchkriterien.auswahlSprache?.map((sprache, index) => (
                        <div className="flex-content-cust">
                          <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d36-1e498197" className="text-block-left-copy">
                            {sprache.sprache}
                          </div>
                          <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d38-1e498197" className="text-block-left-copy">
                            {sprache.niveau}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="card-text-blue">Gewünschtes Beschäftigungsverhältnis</div>
                    <div className="w-layout-grid grid-1x4-170px">
                      {suchkriterien.auswahlArbeitsverhaeltnis?.map((arbeitsverhaeltnis, index) => (
                        <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d47-1e498197" className="text-grid-white-backr">
                          {arbeitsverhaeltnis}
                        </div>
                      ))}
                    </div>

                    <div className="card-text-blue">Wo ich arbeiten möchte</div>
                    {isFlexibel() ? (
                      <div className="card-field-min-215px">
                        Ich bin komplett flexibel
                        <br />
                      </div>
                    ) : (
                      <div className="w-layout-grid grid-77">
                        <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8f1-1e498191" className="text-block-left-copy">
                          {suchkriterien.arbeitsort['arbeitsort']}
                        </div>
                        <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8f3-1e498191" className="text-block-5">
                          +{suchkriterien.arbeitsort['umkreis']}
                        </div>
                      </div>
                    )}

                    <div className="card-text-blue">Bereitschaft für Montageeinsätze</div>
                    <div className="card-field-min-215px">{suchkriterien.auswahlMontage}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default MatchDetailArbeitgeber;
