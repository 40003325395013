import { Link } from 'react-router-dom';
import React from 'react';

export const SucheArbeitgeberDeaktiviert = () => {
  return (
    <section className="container-std w-container">
      <div className="flex-content-cust card-edit-dark-grey-735px-1">
        <div>
          <h1 className="heading-3-copy-copy">Ihre Suche ist zurzeit deaktiviert</h1>
          <p className="paragraph-std">
            Sie haben die Suche deaktiviert. Um die Suche nutzen zu können, aktivieren Sie diese in Ihrem Profil.
            <br />
          </p>
        </div>
        <div id="8-single-select">
          <Link to={`/arbeitgeber/einstellungen`} className="call-to-action-max w-button">
            Jetzt Suche aktivieren
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SucheArbeitgeberDeaktiviert;
