import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getArbeitgeber } from '../../shared/reducers/arbeitgeber.reducer';
import { updateEntity as updateEntityLocal } from 'app/shared/reducers/arbeitgeber.reducer';
import { toast } from 'react-toastify';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import { Domains } from 'app/shared/model/enumerations/domains.model';
import { MitarbeiterAnzahl, Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { ArbeitnehmerEigenschaftenAuswahl } from 'app/shared/components/arbeitgeber/arbeitgeberAuswahl';
import DropzoneComponent from 'app/shared/components/arbeitgeber/logo-upload';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { ValidatedField } from 'react-jhipster';
import { Link, useNavigate } from 'react-router-dom';
import { ArbeitgeberEigenschaften } from 'app/shared/components/arbeitgeber-eigenschaften-auswahl';

// Es müssen die Werte von den Enums statt die Keys verwendet werden
export const ArbeitgeberUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);

  /* Load model */
  const arbeitgeberEntity = useAppSelector(state => state.arbeitgeber.entity);

  const [arbeitgeberChange, setArbeitgeberChange] = useState<IArbeitgeber>();

  useEffect(() => {
    if (!arbeitgeberEntity.userID) {
      dispatch(getArbeitgeber(account.id));
    }
  }, []);

  useEffect(() => {
    setArbeitgeberChange(arbeitgeberEntity);
  }, [arbeitgeberEntity]);

  function save() {
    const arbeitgeberSave = { arbeitgeber: arbeitgeberEntity, ...arbeitgeberChange };

    dispatch(updateEntityLocal(arbeitgeberSave))
      .then(() => navigate('/suche/arbeitgeber/profiles'))
      .catch(error => {
        console.error('Error creating entities:', error);
        toast.error('Error creating entities', error);
      });
  }

  function updateEntity(feld: string, value: any) {
    setArbeitgeberChange({ ...arbeitgeberChange, [feld]: value });
  }

  /* custom functions */
  const [uploadedFile, setUploadedFile] = useState(null);
  const handleFileUpload = useCallback(files => {
    if (files.length > 0) {
      setUploadedFile(files[0]);
    } else {
      setUploadedFile(null);
    }
  }, []);

  return (
    arbeitgeberEntity &&
    arbeitgeberChange && (
      <div>
        <div>
          <div className="container-std w-container">
            <div className="card-edit-dark-grey-735px-1">
              <h1 className="heading-3-23">Unternehmensprofil</h1>
              <div className="form-block-91 w-form">
                <GenericAuswahl
                  label="Branchen"
                  minSelect={1}
                  maxSelect={99}
                  initialValue={arbeitgeberChange.auswahlBranchen}
                  onAuswahlChange={value => updateEntity('auswahlBranchen', value)}
                  col={Domains}
                  title={'In welchen Branchen sind Sie tätig?'}
                />
              </div>

              <div className="divider" />

              <GenericAuswahl
                label="Mitarbeiteranzahl"
                minSelect={1}
                maxSelect={1}
                initialValue={arbeitgeberChange.auswahlMitarbeiterAnzahl}
                onAuswahlChange={value => updateEntity('auswahlMitarbeiterAnzahl', value)}
                col={MitarbeiterAnzahl}
                title={'Wie viele Mitarbeiter hat Ihr Unternehmen?'}
              />
              <div className="divider" />
              <GenericAuswahl
                label="Quereinsteiger"
                initialValue={arbeitgeberChange.auswahlQuereinsteiger}
                onAuswahlChange={value => updateEntity('auswahlQuereinsteiger', value)}
                col={Quereinsteiger}
                title={'Ist ein Quereinstieg in Ihrem Unternehmen möglich?'}
                minSelect={1}
                maxSelect={1}
              />
              <div className="divider" />
              <ArbeitnehmerEigenschaftenAuswahl
                title={'Was ist Ihnen bei einem Mitarbeiter wichtig? (max. 4 Punkte)'}
                minSelect={1}
                maxSelect={4}
                initialValue={arbeitgeberChange.auswahlArbeitnehmerEigenschaften}
                onAuswahlChange={value => updateEntity('auswahlArbeitnehmerEigenschaften', value)}
              />
              <div className="divider" />
              <ArbeitgeberEigenschaften
                minSelect={1}
                maxSelect={8}
                title={'Was zeichnet Sie besonders aus als Unternehmen? (max. 8 Punkte)'}
                initialValue={arbeitgeberChange.auswahlArbeitgeberEigenschaften}
                onAuswahlChange={value => updateEntity('auswahlArbeitgeberEigenschaften', value)}
              />
              <div className="divider" />
              <div className="wrapper-100">
                <p className="paragraph-workflow">
                  <strong>Beschreiben Sie Ihr Unternehmen in wenigen Sätzen.</strong>
                </p>
                <div className="padding-10"></div>
                <label htmlFor="profile-description" className="field-label-std">
                  Unternehmensbeschreibung (optional)
                </label>
                <textarea
                  style={{ width: '100%' }}
                  className="text-field-big w-input"
                  maxLength={5000}
                  name="field"
                  data-name="beschreibung"
                  data-wf-id='["feafc913-1024-7116-67d4-cbf63a12a860"]'
                  key="beschreibung"
                  value={arbeitgeberChange.beschreibung}
                  onChange={e => updateEntity('beschreibung', e.target.value)} // Änderung hier
                  id="profile-description"
                ></textarea>
              </div>
              <div className="divider" />
              <div className="div-block-workflow">
                <p className="paragraph-workflow">
                  <strong>Laden Sie hier Ihr Unternehmenslogo hoch.</strong>
                </p>
                <div className="padding-10"></div>

                <DropzoneComponent initial={uploadedFile} onFileUpload={handleFileUpload} />
              </div>
              <div className="divider" />
              <div className="div-block-workflow">
                {/* Kontaktdaten des unternehmens */}
                <FlendlyValidateField
                  wrapperClass="wrapper-100"
                  placeholder="z.B. Max Mustermann GmbH"
                  label="Unternehmensname"
                  id="arbeitgeber-unternehmensname"
                  name="unternehmensname"
                  value={arbeitgeberChange.unternehmensname}
                  onChange={value => updateEntity('unternehmensname', value.target.value)}
                  headline={'Wie lautet der Name Ihres Unternehmens?'}
                />
              </div>
              <div className="divider" />
              <div className="div-block-workflow">
                <p className="paragraph-workflow">
                  <strong>Geben Sie hier Ihre Unternehmenswebsite an (optional)</strong>
                </p>
                <div className="padding-10"></div>

                <div className="form-group-full">
                  <ValidatedField
                    label="Unternehmenswebsite"
                    id="arbeitgeber-webseite"
                    inputClass="text-field-45px w-input"
                    labelClass="field-label-std"
                    name="webseite"
                    data-cy="webseite"
                    style={{
                      color: arbeitgeberChange.webseite ? 'black' : 'gray',
                    }}
                    onChange={value => updateEntity('webseite', value.target.value)}
                    value={arbeitgeberChange.webseite}
                  />
                </div>
              </div>
              <div className="divider" />
              <div className="div-block-workflow">
                <p className="paragraph-workflow">
                  <strong>Wie lauten Ihre Kontaktdaten?</strong>
                </p>
                <div className="padding-10"></div>
                <div className="wrapper-100">
                  <FlendlyValidateField
                    wrapperClass="wrapper-100"
                    inputClass="text-field-45px w-input"
                    labelClass="field-label-std"
                    label="Straße und Hausnummer"
                    id="arbeitgeber-adresse"
                    name="adresse"
                    data-cy="adresse"
                    onChange={value => updateEntity('adresse', value.target.value)}
                    value={arbeitgeberChange.adresse}
                  />
                  <FlendlyValidateField
                    wrapperClass="wrapper-100"
                    inputClass="text-field-45px w-input"
                    labelClass="field-label-std"
                    onChange={value => updateEntity('zusatz', value.target.value)}
                    label="Adresszusatz (optional)"
                    value={arbeitgeberChange.zusatz}
                    id="arbeitgeber-zusatz"
                    name="zusatz"
                    data-cy="zusatz"
                    type="text"
                  />

                  <div className="w-layout-grid grid-6">
                    <ValidatedField
                      inputClass="text-field-45px w-input"
                      labelClass="field-label-std form-label"
                      label="Ort"
                      id="arbeitgeber-ort"
                      name="ort"
                      data-cy="ort"
                      value={arbeitgeberChange.ort}
                      style={{
                        color: arbeitgeberChange.ort ? 'black' : 'gray',
                      }}
                      onChange={value => updateEntity('ort', value.target.value)}
                    />
                    <ValidatedField
                      labelClass="field-label-std"
                      inputClass="text-field-45px w-input"
                      label="PLZ"
                      id="arbeitgeber-plz"
                      name="plz"
                      data-cy="plz"
                      style={{
                        color: arbeitgeberChange.plz ? 'black' : 'gray',
                      }}
                      onChange={value => updateEntity('plz', value.target.value)}
                      value={arbeitgeberChange.plz}
                    />
                  </div>
                  <FlendlyValidateField
                    wrapperClass="wrapper-100"
                    inputClass="text-field-45px w-input"
                    labelClass="field-label-std"
                    label="Telefon"
                    id="arbeitgeber-telefon"
                    name="telefon"
                    onChange={value => updateEntity('telefon', value.target.value)}
                    value={arbeitgeberChange.telefon}
                    data-cy="telefon"
                  />
                  <FlendlyValidateField
                    wrapperClass="wrapper-100"
                    inputClass="text-field-45px w-input"
                    labelClass="field-label-std"
                    label="Ansprechpartner"
                    id="arbeitgeber-anstprechpartner"
                    name="ansprechpartner"
                    value={arbeitgeberChange.ansprechpartner}
                    data-cy="ansprechpartner"
                    onChange={value => updateEntity('ansprechpartner', value.target.value)}
                  />
                </div>
              </div>
              <div className="padding-20"></div>
              <div className="div-block-workflow">
                <div className={'flendly-button-bar'}>
                  <Link className="button-workflow-forward w-button" to="/suche/arbeitgeber/profiles">
                    Abbrechen
                  </Link>

                  <button className="call-to-action-max w-button" onClick={() => save()}>
                    Speichern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
