import { Link } from 'react-router-dom';
import React from 'react';
import { getRecommendationsForArbeitgeber } from 'app/modules/suche/recommendation/recommendation.reducer';
import { useAppDispatch } from 'app/config/store';

export const SucheArbeitgeberKeineTreffer = ({ id }) => {
  const dispatch = useAppDispatch();

  function handleShowDeclinedRecommendations() {
    dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: true }));
  }

  return (
    <section className="container-std w-container">
      <div className="flex-content-cust card-edit-dark-grey-735px-1">
        <div>
          <h1 className="heading-3">Keine weiteren Treffer für Ihre Suche</h1>
          <p className="paragraph-std">
            Im Moment gibt es keine weiteren passenden Kandidaten für dieses Suchprofil. Falls Sie nicht warten wollen bis weitere
            Kandidaten verfügbar sind, können Sie folgendes tun:
            <br />
          </p>
        </div>
        <div className="padding-5"></div>
        <div>
          <p className="paragraph-std">
            Passen Sie Ihr Suchprofil an, um mehr Kandidaten zu erreichen (z.B. Gehalt erhöhen, Qualifikation oder Berufserfahrung
            reduzieren)
          </p>
        </div>

        <div id="8-single-select">
          <Link to={`/suche/arbeitgeber/profiles/update/${id}`} className="call-to-action-max w-button">
            jetzt profil bearbeiten
          </Link>
        </div>
        <div className={'wrapper-100'}>
          <p className="paragraph-std">
            ODER
            <br />‍<br />
            Schauen Sie die Liste der abgelehnten Kandidaten nochmals an. In der schnellen Durchsicht drückt man vielleicht einmal zu
            schnell auf den &quot;nicht interessant&quot; Button.
          </p>
        </div>
        <div id="8-single-select">
          {/*TODO abgelehnte Unternehmen nochmal einblenden*/}
          <a className="call-to-action-max w-button" onClick={() => handleShowDeclinedRecommendations()}>
            Abgelehnte Kanditaten nochmals anschauen
          </a>
        </div>
        <div className="wrapper-100">
          <p className="paragraph-std">
            ODER
            <br />
          </p>
        </div>
        <div id="8-single-select">
          {/*TODO Option für Notification wenn weitere Unternehmen verfügbar sind*/}
          <a href="#" className="call-to-action-max w-button">
            Warten bis weitere Kanditaten verfügbar sind
          </a>
        </div>
        <div className="padding-10"></div>
      </div>
    </section>
  );
};
