export interface IArbeitnehmer {
  id?: string;
  userID: string | null;
  auswahlAnrede: string | null;
  vorname: string | null;
  name: string | null;
  email: string | null;
  geburtsdatum: string | null;
  telefon: string | null;
  auswahlArbeitnehmerEigenschaften: string[] | null;
  auswahlBlockedCompanies?: string[] | null;
  active: boolean;
  profilePictureUrl: string | null;
}

export const defaultArbeitnehmer: Readonly<IArbeitnehmer> = {
  userID: null,
  active: false,
  auswahlAnrede: null,
  vorname: null,
  name: null,
  email: null,
  geburtsdatum: null,
  telefon: null,
  auswahlArbeitnehmerEigenschaften: [],
  auswahlBlockedCompanies: [],
  profilePictureUrl: null,
};
