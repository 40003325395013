import { Card, Col, Container, Row } from 'reactstrap';
import React from 'react';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { Link } from 'react-router-dom';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { deleteEntity } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { useAppDispatch } from 'app/config/store';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';

interface SucheArbeitgeberProfilCardProps {
  arbeitnehmersuche: IArbeitnehmersuche;
  index: number;
  deleteProfile: (id: string) => void;
}

export const SucheArbeitgeberProfilCard: React.FC<SucheArbeitgeberProfilCardProps> = ({ arbeitnehmersuche, index, deleteProfile }) => {
  const dispatch = useAppDispatch();

  return (
    <div className={'w-layout-grid grid-fixed'} key={index} style={{ margin: '5px' }}>
      <Container fluid>
        <Row>
          <Col>
            <Card style={{ backgroundColor: '#dbe3ed', padding: '15px' }}>
              <Link to={'/suche/arbeitgeber/' + arbeitnehmersuche.id} className="link-block-card w-inline-block"></Link>

              <Row>
                <h5>{Berufe[arbeitnehmersuche.auswahlBeruf]}</h5>
              </Row>
              <div style={{ padding: '15px' }}>
                <div className="padding-1"></div>
                <Row>
                  <div className={'simple-tile'}>{arbeitnehmersuche.standort.arbeitsort}</div>
                </Row>
                <div className="padding-1"></div>
                <Row>
                  <div className={'simple-tile'}>
                    {'<'} {arbeitnehmersuche.gehalt} €
                  </div>
                </Row>
                <div className="padding-1"></div>
                <Row>
                  <div className={'simple-tile'}>
                    {arbeitnehmersuche.auswahlAusbildungsstand
                      .map((ausbildungsstand: Ausbildungsstand) => Ausbildungsstand[ausbildungsstand])
                      .join(', ')}
                  </div>
                </Row>
                <div className="padding-1"></div>
                <Row>
                  <div className={'simple-tile'}>
                    {arbeitnehmersuche.auswahlBerufserfahrung.map((berufserfahrung: Berufserfahrung) => Berufserfahrung[berufserfahrung])}
                  </div>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Link
        id="w-node-_731c0316-64f9-044a-b181-8fa53b33633b-8d3b9927"
        to={'/suche/arbeitgeber/profiles/update/' + arbeitnehmersuche.id}
        className="w-inline-block"
      >
        <img
          src="../../../../content/images/edit2.svg"
          loading="lazy"
          id="w-node-_62efc283-2f8a-058c-5911-e86e0b813170-8d3b9927"
          alt="Ein stilisiertes Symbol für das Bearbeiten von Informationen, dargestellt durch ein Icon bestehend aus einem Stift auf Papier."
          className="image-15"
        />
      </Link>
      <Link
        to={''}
        id="w-node-_731c0316-64f9-044a-b181-8fa53b33633b-8d3b9927"
        className="w-inline-block"
        onClick={() => {
          deleteProfile(arbeitnehmersuche.id);
        }}
      >
        <img
          src="../../../../content/images/delete2.PNG"
          loading="lazy"
          id="w-node-_62efc283-2f8a-058c-5911-e86e0b813170-8d3b9927"
          alt="Ein stilisiertes Symbol für das Löschen von Informationen, dargestellt durch ein Icon bestehend aus einem Mülleimer."
          className="image-15"
        />
      </Link>
    </div>
  );
};
