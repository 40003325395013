import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { toast } from 'react-toastify';
import { getRecommendationsForArbeitgeber, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByUserId as getSuchkriterienFor } from 'app/shared/reducers/suchkriterien.reducer';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { Container } from 'reactstrap';
import SucheDetailsArbeitgeberDesktop from 'app/modules/suche/arbeitgeber/details-arbeitgeber-desktop';
import SucheDetailsArbeitgeberMobile from 'app/modules/suche/arbeitgeber/details-arbeitgeber-mobile';

const SucheArbeitgeberDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  const recommendationList: IRecommendation[] = useAppSelector(state => state.recommendation.entities);
  const arbeitnehmerSuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);
  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);

  const [recommendation, setRecommendation] = React.useState<IRecommendation>();

  useEffect(() => {
    if (recommendationList.length > 0) {
      const iRecommendation = recommendationList.find(r => r.id === id);

      if (iRecommendation) {
        setRecommendation(iRecommendation);

        dispatch(getArbeitnehmer(iRecommendation.arbeitnehmerId));
        dispatch(getSuchkriterienFor(iRecommendation.arbeitnehmerId));
      } else {
        toast.error('Error loading recommendation');
      }
    } else if (recommendationList.length === 0) {
      toast.error('Error loading recommendation list');
    }
  }, []);

  function handleRecommendationDeclined() {
    const updatedRecommendation: IRecommendation = { ...recommendation, status: IRecommendationStatus.REJECTED };

    dispatch(updateRecommendation(updatedRecommendation))
      .then(() => {
        toast.info('Arbeitnehmer wurde abgelehnt');
      })
      .then(() => {
        dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: arbeitnehmerSuche.id, includeDeclined: false }));
      })
      .then(() => {
        navigate('/suche/arbeitgeber/' + arbeitnehmerSuche.id);
      })
      .catch(reason => toast.error('Error updating recommendation: \n' + reason));
  }

  function loaded() {
    return recommendation && arbeitnehmer && suchkriterien && arbeitgeber;
  }

  return (
    <div>
      {loaded() && (
        <div>
          <div className="padding-20" />
          <Container fluid>
            <SucheDetailsArbeitgeberDesktop
              handleRecommendationDeclined={handleRecommendationDeclined}
              arbeitnehmer={arbeitnehmer}
              suchkriterien={suchkriterien}
              recommendation={recommendation}
              arbeitnehmersuche={arbeitnehmerSuche}
              id={id}
            />
            <SucheDetailsArbeitgeberMobile
              handleRecommendationDeclined={handleRecommendationDeclined}
              arbeitnehmer={arbeitnehmer}
              suchkriterien={suchkriterien}
              recommendation={recommendation}
              arbeitnehmersuche={arbeitnehmerSuche}
              id={id}
            />
          </Container>
          <div className="padding-20" />
        </div>
      )}
    </div>
  );
};

export default SucheArbeitgeberDetails;
