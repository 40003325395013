import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultArbeitnehmer, IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';

import { v4 as uuidv4 } from 'uuid';

const initialState: EntityState<IArbeitnehmer> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultArbeitnehmer,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/arbeitnehmers';

/*Profile picture */

// Aktion: Profilbild hochladen
export const uploadProfilePicture = (id: number, file: File) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    await axios.post(`/api/profile-picture/upload/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // Nach erfolgreichem Upload: Arbeitnehmer neu laden
    dispatch(getArbeitnehmer(id));
  } catch (error) {
    console.error('Fehler beim Hochladen des Profilbilds:', error);
  }
};

// Aktion: Profilbild löschen
export const deleteProfilePicture = (id: number) => async dispatch => {
  try {
    await axios.delete(`/api/profile-picture/delete/${id}`);
    // Nach erfolgreichem Löschen: Arbeitnehmer neu laden
    dispatch(getArbeitnehmer(id));
  } catch (error) {
    console.error('Fehler beim Löschen des Profilbilds:', error);
  }
};

// Actions

export const getEntities = createAsyncThunk('arbeitnehmer/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IArbeitnehmer[]>(requestUrl);
});

export const getArbeitnehmer = createAsyncThunk(
  'arbeitnehmer/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IArbeitnehmer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getArbeitnehmerByIds = createAsyncThunk(
  'arbeitnehmer/fetch_entity',
  async (ids: string[]) => {
    /*make the ids as request body */
    const requestUrl = `${apiUrl}/ids`;
    return axios.post<IArbeitnehmer[]>(requestUrl, ids);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'arbeitnehmer/create_entity',
  async (entity: IArbeitnehmer, thunkAPI) => {
    const result = await axios.post<IArbeitnehmer>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendAnonymousInterviewStatistics = createAsyncThunk(
  'arbeitnehmer/sendAnonymousInterviewStatistics',
  async (count: number, thunkAPI) => {
    const uniqueId = uuidv4();
    await axios.get(apiUrl + '/sendAnonymousInterviewStatistics/' + uniqueId + '/' + count, {});
  },
  { serializeError: serializeAxiosError }
);

export const createByInterview = createAsyncThunk(
  'arbeitnehmer/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<any>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createEntityFromInterview = createAsyncThunk(
  'arbeitnehmer/interview',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IArbeitnehmer>(apiUrl + '/interview', cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'arbeitnehmer/update_entity',
  async (entity: IArbeitnehmer, thunkAPI) => {
    const result = await axios.put<IArbeitnehmer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'arbeitnehmer/partial_update_entity',
  async (entity: IArbeitnehmer, thunkAPI) => {
    const result = await axios.patch<IArbeitnehmer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'arbeitnehmer/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IArbeitnehmer>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ArbeitnehmerSlice = createEntitySlice({
  name: 'arbeitnehmer',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getArbeitnehmer.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultArbeitnehmer;
      })
      // Upload Start: Loading auf true setzen
      .addCase('arbeitnehmer/uploadProfilePicture/pending', state => {
        state.loading = true;
      })

      // Upload Erfolg: Loading auf false setzen
      .addCase('arbeitnehmer/uploadProfilePicture/fulfilled', state => {
        state.loading = false;
      })

      // Upload Fehler: Loading auf false setzen
      .addCase('arbeitnehmer/uploadProfilePicture/rejected', (state, action) => {
        state.loading = false;
      })
      .addMatcher(isFulfilled(getEntities, getArbeitnehmerByIds), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getArbeitnehmer, getArbeitnehmerByIds), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ArbeitnehmerSlice.actions;

// Reducer
export default ArbeitnehmerSlice.reducer;
