import React, { useEffect, useState } from 'react';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { defaultValue, IBeruf } from 'app/shared/model/beruf.model';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';
import { useAppSelector } from 'app/config/store';
import { FormGroup, Input, Label } from 'reactstrap';

interface MultiSelectProps {
  onAuswahlChange: (value: any) => void;
  initialValue: IBeruf;
  formEnabled?: boolean;
}

export const BerufAuswahlMultiSelect: React.FC<MultiSelectProps> = ({ onAuswahlChange, initialValue }) => {
  const [beruf, setBeruf] = useState<IBeruf>(defaultValue);
  const [anzeige, setAnzeige] = useState(false);

  const account = useAppSelector(state => state.authentication.account);

  useEffect(() => {
    if (initialValue) {
      setBeruf(initialValue);
      if (initialValue.auswahlBeruf === 'MALER_UND_LACKIERER') {
        setAnzeige(true);
      }
    } else {
      setBeruf(prev => {
        return { ...prev, beruf: { ...prev, ['userID']: account.id } };
      });
    }
  }, []);

  function setBerufValue(value: any, field: string) {
    // Erstellen einer Kopie des "beruf"-Objekts
    let currentberuf = { ...beruf };

    // Modifizieren der Kopie
    currentberuf[field] = value;

    // Aktualisieren des States
    setBeruf(currentberuf);

    // Weiteres Handling
    onAuswahlChange(currentberuf);
    setAnzeige(currentberuf.auswahlBeruf === 'MALER_UND_LACKIERER');
  }

  return (
    <div>
      <div>
        <GenericAuswahl
          label="Beruf"
          minSelect={1}
          maxSelect={1}
          initialValue={[beruf.auswahlBeruf]}
          onAuswahlChange={value => setBerufValue(value[0], 'auswahlBeruf')}
          col={Berufe}
          title={'Welchen Beruf hast Du?'}
        />

        <div className="padding-10"></div>

        {anzeige && (
          <p className="paragraph-workflow">
            <strong>Was ist Deine Fachrichtung? (optional)</strong>
            <div className="padding-10"></div>
          </p>
        )}

        {anzeige && (
          <FormGroup className="form-group-full">
            <div>
              <Label for="sprachenSelect" className="field-label-std">
                Fachrichtung
              </Label>
              <Input
                className="text-field-45px w-input"
                id="sprachenSelect"
                placeholder="Bitte wähle eine Fachrichtung"
                name="select"
                type="select"
                onChange={value => setBerufValue(value.target.value, 'auswahlFachbereich')}
                value={beruf.auswahlFachbereich}
                style={{
                  color: beruf.auswahlFachbereich ? 'black' : 'gray',
                }}
              >
                <option value="" disabled selected hidden>
                  Bitte wähle eine Fachrichtung aus
                </option>
                <option value="Gestaltung und Instandhaltung">Gestaltung und Instandhaltung</option>
                <option value="Bau- und Möbelmalerei">Bau- und Möbelmalerei</option>
                <option value="Fassaden- und Korrosionsschutz">Fassaden- und Korrosionsschutz</option>
                <option value="Industrielackierung">Industrielackierung</option>
              </Input>
            </div>
          </FormGroup>
        )}

        <div className="padding-10"></div>

        <GenericAuswahl
          label="Ausbildungsstand"
          minSelect={1}
          maxSelect={1}
          initialValue={[beruf.auswahlAusbildungsstand]}
          onAuswahlChange={value => setBerufValue(value[0], 'auswahlAusbildungsstand')}
          col={Ausbildungsstand}
          title={'Welchen Ausbildungsstand hast Du?'}
        />

        <GenericAuswahl
          label="Berufserfahrung"
          minSelect={1}
          maxSelect={1}
          initialValue={[beruf.auswahlBerufserfahrung]}
          onAuswahlChange={value => setBerufValue(value[0], 'auswahlBerufserfahrung')}
          col={Berufserfahrung}
          title={'Wie viel Berufserfahrung hast Du?'}
        />
      </div>
    </div>
  );
};
