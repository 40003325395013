import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteProfilePicture, uploadProfilePicture } from 'app/shared/reducers/arbeitnehmer.reducer';
import { MdCreate, MdDelete } from 'react-icons/md';
import { Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';

const ProfileImageUploader: React.FC = () => {
  const arbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity); // Zugriff auf Arbeitnehmer-Daten aus dem
  // Store
  const loading = useAppSelector(state => state.arbeitnehmer.loading);
  const dispatch = useAppDispatch();

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // Maximale Dateigröße: 5 MB

  // Handler für Bild-Uploads
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !arbeitnehmer.userID) return;

    // Prüfen der Dateigröße
    if (file.size > MAX_FILE_SIZE) {
      toast.error(`Die Datei ist zu groß! Maximal erlaubte Größe: 5 MB.`);
      return;
    }

    dispatch(uploadProfilePicture(arbeitnehmer.userID, file));
  };

  // Bild löschen
  const handleDeleteImage = () => {
    if (!arbeitnehmer.userID) return;
    dispatch(deleteProfilePicture(arbeitnehmer.userID)); // Aktion dispatchen
  };

  return (
    <Container>
      <Row>
        <div className="profile-image-uploader">
          <h3>Profilbild verwalten</h3>
          {/* Profilbild anzeigen */}
          {arbeitnehmer?.profilePictureUrl == null ? (
            <p>Kein Profilbild vorhanden.</p>
          ) : (
            <div className="profile-image-container">
              <img src={arbeitnehmer?.profilePictureUrl} alt="Profilbild" className="profile-image" />
            </div>
          )}
          {/* Bild hochladen */}
        </div>
      </Row>
      <Row className={'profile-picture-buttons'}>
        <Col xs="auto" className="p-0">
          <label onClick={handleDeleteImage} className={'delete-button'}>
            <MdDelete />
          </label>
        </Col>
        <Col xs="auto" className="p-0">
          <label htmlFor="profile-image-input" className="upload-button">
            {loading ? 'Upload läuft...' : <MdCreate />}
            <input
              id="profile-image-input"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              disabled={loading}
              style={{ display: 'none' }}
            />
          </label>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileImageUploader;
