import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { toast } from 'react-toastify';
import { getRecommendationsForArbeitgeber, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByUserId as getSuchkriterienFor } from 'app/shared/reducers/suchkriterien.reducer';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { License } from 'app/shared/model/enumerations/license.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { getAgeByDateOfBirth } from 'app/shared/util/date-utils';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { MdAccessTime, MdEuro, MdSchool } from 'react-icons/md';

interface SucheArbeitgeberProps {
  arbeitnehmersuche: IArbeitnehmersuche;
  id: string;
  recommendation: IRecommendation;
  handleRecommendationDeclined: (value: IRecommendation) => void;
  arbeitnehmer: IArbeitnehmer;
  suchkriterien: ISuchkriterien;
}

const SucheDetailsArbeitgeberMobile: React.FC<SucheArbeitgeberProps> = ({
  id,
  recommendation,
  handleRecommendationDeclined,
  arbeitnehmer,
  suchkriterien,
}) => {
  const navigate = useNavigate();

  return (
    <Row className="d-md-none">
      <Col>
        <Card style={{ backgroundColor: '#dbe3ed', margin: '15px' }}>
          <CardBody className={'mobile-tile'}>
            <h3>
              <b>
                {arbeitnehmer.vorname}, {getAgeByDateOfBirth(arbeitnehmer.geburtsdatum)}
              </b>
            </h3>
            <h4>{Berufe[suchkriterien.auswahlBeruf?.auswahlBeruf]}</h4>
            {arbeitnehmer.profilePictureUrl ? (
              <img src={arbeitnehmer.profilePictureUrl} alt="Profilbild" className="rounded-circle" />
            ) : (
              <img src="../../../../content/images/portrait-placeholder.jpg" alt="Profilbild" className="rounded-circle" />
            )}
            {/*BERUF*/}
            <h6>MEINE AUSBILDUNG</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              <div className={'simple-tile'}>
                <div className={'mobile-icon'}>
                  <MdSchool />
                </div>
                <div className={'width-100'}>
                  <b>{Ausbildungsstand[suchkriterien.auswahlBeruf?.auswahlAusbildungsstand]}</b>
                </div>
              </div>
              <div className={'simple-tile'}>
                <div className={'mobile-icon'}>
                  <MdAccessTime />
                </div>
                <div className={'width-100'}>{Berufserfahrung[suchkriterien.auswahlBeruf?.auswahlBerufserfahrung]}</div>
              </div>
              <div className={'simple-tile'}>{suchkriterien.auswahlBeruf?.auswahlFachbereich}</div>
            </div>
            <div className="padding-15" />

            {/* ICH BIN */}
            <h6>ICH BIN</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              {arbeitnehmer.auswahlArbeitnehmerEigenschaften.map((eigenschaft, index) => (
                <li key={index} className={'simple-tile'}>
                  {eigenschaft}
                </li>
              ))}
            </div>
            <div className="padding-15" />

            {/*DAS IST MIR WICHTIG */}
            {/* ICH BIN */}
            <h6>DAS IST MIR WICHTIG</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              {suchkriterien.auswahlEigenschaften.map((eigenschaft, index) => (
                <li key={index} className={'simple-tile'}>
                  {eigenschaft}
                </li>
              ))}
            </div>
            <div className="padding-15" />

            {/*Verdienst*/}
            <h6>DAS WILL ICH VERDIENEN (BRUTTO)</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              <div className={'simple-tile'}>
                <div className={'mobile-icon'}>
                  <MdEuro />
                </div>
                <div className={'width-100'}>
                  <b>
                    {'>'} {suchkriterien.auswahlGehalt} € pro Monat
                  </b>
                </div>
              </div>
            </div>
            <div className="padding-15" />

            {/*Führerscheine*/}
            <h6>MEINE FÜHRERSCHEINE</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              {suchkriterien.auswahlLicense?.map((license, index) => (
                <div className={'simple-tile'} key={index}>
                  <b>{License[license]}</b>
                </div>
              ))}
            </div>
            <div className="padding-15" />

            {/*Arbeitsort*/}

            <h6>HIER WILL ICH ARBEITEN</h6>

            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              <div className={'simple-tile'}>
                <b>{suchkriterien.arbeitsort.arbeitsort}</b>
              </div>
              <div className={'simple-tile'}>
                <b>+{suchkriterien.arbeitsort.umkreis} km</b>
              </div>
            </div>
            <div className="padding-15" />

            {/*Arbeitszeit*/}
            <h6>SO KANN ICH ARBEITEN</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              {suchkriterien.auswahlArbeitsverhaeltnis?.map((arbeitsverhaeltnis, index) => (
                <div className={'simple-tile'} key={index}>
                  <b>{arbeitsverhaeltnis}</b>
                </div>
              ))}
            </div>
            <div className="padding-15" />

            {/*Arbeitszeit*/}
            <h6>MEHRTÄGIGE MONTAGEEINSÄTZE</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              <div className={'simple-tile'}>
                <b>{suchkriterien.auswahlMontage}</b>
              </div>
            </div>
            <div className="padding-15" />

            {/* BUTTON BAR */}
            <Row style={{ width: '100%' }} className={'gap-15'}>
              <Col className={'no-padding'}>
                <div className={'workflow-button-max w-button'} onClick={() => handleRecommendationDeclined(recommendation)}>
                  NICHT INTERESSANT
                </div>
              </Col>
              <Col className={'no-padding'}>
                <div className={'call-to-action-max w-button'} onClick={() => navigate('/suche/arbeitgeber/angebot/' + id)}>
                  EINLADUNG SENDEN
                </div>
              </Col>
            </Row>
            <div className="padding-15" />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SucheDetailsArbeitgeberMobile;
