import React, { FC, useEffect, useState } from 'react';
import { InputGroup, InputGroupText, Input } from 'reactstrap';

export const GehaltAuswahl = ({ onAuswahlChange, initialValue, wrapperClass, title, label }) => {
  const [selectGehalt, setSelectGehalt] = useState('');

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      setSelectGehalt(initialValue);
      onAuswahlChange(initialValue);
    } else {
      setSelectGehalt('');
      onAuswahlChange('');
    }
  }, [initialValue]);

  function handleSelectGehalt(event) {
    const gehalt = event.target.value;
    setSelectGehalt(gehalt);
    onAuswahlChange(gehalt);
  }

  return (
    <div>
      <div>
        <p className="paragraph-workflow">
          <strong>{title}</strong>
        </p>
        <div className="padding-10"></div>
      </div>
      <div className="field-label-std">
        <strong>{label}</strong>
      </div>
      <InputGroup>
        <Input
          type="number"
          className="text-field-45px w-input"
          placeholder="z.B. 2.400"
          onChange={handleSelectGehalt}
          value={selectGehalt}
        />
        <InputGroupText typeof="number" style={{ height: '45px', background: 'white', border: '2px solid #0fd7cd', color: '#0fd7cd' }}>
          €
        </InputGroupText>
      </InputGroup>
    </div>
  );
};
