import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getMatchesForArbeitgeber } from 'app/shared/reducers/match.reducer';
import { IMatch } from 'app/shared/model/match.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { getArbeitnehmerByIds } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByIds } from 'app/shared/reducers/suchkriterien.reducer';
import { getAgeByDateOfBirth, getDateByTimestamp } from 'app/shared/util/date-utils';

export const MatchArbeitgeber = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const matchesList: IMatch[] = useAppSelector(state => state.match.entities);

  const arbeitnehmerListe: IArbeitnehmer[] = useAppSelector(state => state.arbeitnehmer.entities);
  const suchkriterienListe: ISuchkriterien[] = useAppSelector(state => state.suchkriterien.entities);

  /* load matches for the arbeitgeber */
  useEffect(() => {
    dispatch(getMatchesForArbeitgeber(account.id));
  }, []);

  useEffect(() => {
    if (matchesList.length > 0) {
      const arbeitnehmerIds = matchesList.map(match => match.arbeitnehmerId);
      const suchkriterienIds = matchesList.map(match => match.suchkriterienId);

      dispatch(getArbeitnehmerByIds(arbeitnehmerIds));
      dispatch(getSuchkriterienByIds(suchkriterienIds));
    }
  }, [matchesList]);

  function selectArbeitnehmer(arbeitnehmerId: string) {
    return arbeitnehmerListe.find(arbeitnehmer => arbeitnehmer.userID === arbeitnehmerId);
  }

  function selectSuchkriterien(suchkriterienId: string) {
    return suchkriterienListe.find(suchkriterien => suchkriterien.userID === suchkriterienId);
  }

  function loaded() {
    return arbeitnehmerListe.length > 0 && suchkriterienListe.length > 0;
  }

  return (
    <div>
      {loaded() ? (
        <section>
          <div className="container-std w-container">
            <div className="padding-30"></div>
            <div className="w-layout-grid grid-333">
              {matchesList.map((match, index) => (
                <Link
                  key={index}
                  id="w-node-_2e47a038-35c8-d272-e126-d76e9fdb6fd0-ffb3ae21"
                  to={'/matches/arbeitgeber/' + match.id}
                  className="card-matches w-inline-block"
                >
                  <h1 className="card-heading-copy">
                    {selectArbeitnehmer(match.arbeitnehmerId).auswahlAnrede +
                      ' ' +
                      selectArbeitnehmer(match.arbeitnehmerId).vorname +
                      ' ' +
                      selectArbeitnehmer(match.arbeitnehmerId).name +
                      ', ' +
                      getAgeByDateOfBirth(selectArbeitnehmer(match.arbeitnehmerId).geburtsdatum)}
                  </h1>
                  <div className="padding-5"></div>
                  <div className="text-block-card">{selectSuchkriterien(match.suchkriterienId).auswahlBeruf.auswahlBeruf}</div>
                  <div className="padding-5"></div>
                  <div className="text-block-card-small">Match am {getDateByTimestamp(match.created)}</div>
                </Link>
              ))}
            </div>
            <div className="padding-20"></div>
          </div>
        </section>
      ) : (
        <section className="container-std w-container">
          <div className="flex-content-cust card-edit-dark-grey-735px-1">
            <div>
              <h1 className="heading-3">Noch keine Matches vorhanden</h1>
              <p className="paragraph-std">
                Sie haben noch keine Matches, aber das kann sich schnell ändern!
                <br />
              </p>
            </div>
            <div className="padding-5"></div>
            <div>
              <p className="paragraph-std">
                Fangen Sie jetzt an nach potentiellen Kandiaten zu suchen. Richten Sie ihr Suchprofil ein, wenn noch nicht geschehen und wir
                finden Kandidaten für Sie.
              </p>
            </div>

            <div id="8-single-select">
              <Link to={`/suche/arbeitgeber/profiles`} className="call-to-action-max w-button">
                jetzt Suchen
              </Link>
            </div>
            <div className="padding-10"></div>
          </div>
        </section>
      )}
    </div>
  );
};
