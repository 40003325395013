import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getAgeByDateOfBirth } from 'app/shared/util/date-utils';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { IRecommendation } from 'app/shared/model/recommendation.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { useNavigate } from 'react-router-dom';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { getArbeitnehmerSkillsByKey, getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { License } from 'app/shared/model/enumerations/license.model';
import { Md10K, MdAccessTime, MdEuro, MdSchool } from 'react-icons/md';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';

interface SucheArbeitgeberProps {
  arbeitnehmersuche: IArbeitnehmersuche;
  id: string;
  recommendation: IRecommendation;
  handleRecommendationDeclined: (value: IRecommendation) => void;
  arbeitnehmer: IArbeitnehmer;
  suchkriterien: ISuchkriterien;
}

const SucheDetailsArbeitgeberDesktop: React.FC<SucheArbeitgeberProps> = ({
  id,
  recommendation,
  handleRecommendationDeclined,
  arbeitnehmer,
  suchkriterien,
}) => {
  function splitIntoPairs(arr) {
    const result = [];
    for (let i = 0; i < arr.length; i += 2) {
      result.push(arr.slice(i, i + 2));
    }
    return result;
  }

  const navigate = useNavigate();
  const ArbeitnehmerEigenschadften = splitIntoPairs(arbeitnehmer.auswahlArbeitnehmerEigenschaften);
  const ArbeitgeberEigenschadften = splitIntoPairs(suchkriterien.auswahlEigenschaften);

  return (
    <Row className="d-none d-md-flex">
      <Col md={{ size: 6, offset: 3 }}>
        <Card style={{ backgroundColor: '#dbe3ed', padding: '15px' }}>
          <CardBody>
            <Row>
              <Col md="10" className="profil-card-header">
                <h3>
                  <b>
                    {arbeitnehmer.vorname}, {getAgeByDateOfBirth(arbeitnehmer.geburtsdatum)}
                  </b>
                </h3>
                <h4>{Berufe[suchkriterien.auswahlBeruf?.auswahlBeruf]}</h4>
              </Col>
              <Col md="2">
                {arbeitnehmer.profilePictureUrl ? (
                  <img src={arbeitnehmer.profilePictureUrl} alt="Profilbild" className="rounded-circle" />
                ) : (
                  <img src="../../../../content/images/portrait-placeholder.jpg" alt="Profilbild" className="rounded-circle" />
                )}
              </Col>
            </Row>
            <div className="padding-15"></div>
            <Row>
              <Col className={'no-padding'}>
                <h6>MEINE AUSBILDUNG</h6>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row style={{ gap: '15px' }}>
                  <Col className={'simple-tile'}>
                    <div>
                      <MdSchool />
                    </div>
                    <div className={'width-100'}>
                      <b>{Ausbildungsstand[suchkriterien.auswahlBeruf?.auswahlAusbildungsstand]}</b>
                    </div>
                  </Col>
                  <Col className={'simple-tile'}>
                    <div>
                      <MdAccessTime />
                    </div>
                    <div className={'width-100'}>{Berufserfahrung[suchkriterien.auswahlBeruf?.auswahlBerufserfahrung]}</div>
                  </Col>
                </Row>
                <div className="padding-5" />
                <Row>
                  <Col className={'simple-tile'}>
                    <div>
                      <b>{suchkriterien.auswahlZeitpunkt?.zeitpunkt}</b>
                    </div>
                  </Col>
                </Row>
                <div className="padding-15"></div>
                <Row>
                  <Col className={'no-padding'}>
                    <h6>ICH BIN</h6>
                  </Col>
                </Row>
                <div className="padding-5"></div>
                {ArbeitnehmerEigenschadften.map((pair, index) => (
                  <Row key={index} style={{ gap: '15px' }}>
                    {pair.map((item, i) => (
                      <Col key={i} className={'simple-tile-column'}>
                        <Row className="simple-tile margin-bottom-5px">
                          <div>{getArbeitnehmerSkillsByKey(item)}</div>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                ))}

                <div className="padding-15"></div>

                {/* DAS IST MIR WICHITG*/}
                <Row>
                  <Col className={'no-padding'}>
                    <h6>DAS IST MIR WICHTIG </h6>
                  </Col>
                </Row>
                <div className="padding-5"></div>
                {ArbeitgeberEigenschadften.map((pair, index) => (
                  <Row key={index} style={{ gap: '15px' }}>
                    {pair.map((item, i) => (
                      <Col key={i} className={'simple-tile-column'}>
                        <Row className="simple-tile margin-bottom-5px">
                          <div>{getEigenschaftByKey(item)}</div>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                ))}
                <div className="padding-15"></div>

                {/* DAS WILL ICH VERDIENEN*/}
                <Row>
                  <Col className={'no-padding'}>
                    <h6>DAS WILL ICH VERDIENEN (BRUTTO)</h6>
                  </Col>
                </Row>
                <div className="padding-5"></div>
                <Row>
                  <Col sm="6" xs="6" className={'simple-tile'}>
                    <div>
                      <MdEuro />
                    </div>
                    <div className={'width-100'}>
                      {'>'} {suchkriterien.auswahlGehalt} € pro Monat
                    </div>
                  </Col>
                </Row>
                <div className="padding-15"></div>

                {/* MEINE FÜHRERSCHEINE*/}
                <Row>
                  <Col className={'no-padding'}>
                    <h6>MEINE FÜHRERSCHEINE </h6>
                  </Col>
                </Row>
                <div className="padding-5"></div>
                <Row className={'gap-15'}>
                  {suchkriterien.auswahlLicense?.map((license, index) => (
                    <Col className={'simple-tile'}>
                      <div className={'width-100'} key={index}>
                        {License[license]}
                      </div>
                    </Col>
                  ))}
                </Row>

                <div className="padding-15"></div>

                {/*HIER WILL ICH ARBEITEN */}
                <Row>
                  <Col className={'no-padding'}>
                    <h6>HIER WILL ICH ARBEITEN </h6>
                  </Col>
                </Row>
                <div className="padding-5"></div>
                <Row className={'gap-15'}>
                  <Col className={'simple-tile '}>
                    <div className={'width-100'}>{suchkriterien.arbeitsort.arbeitsort}</div>
                  </Col>
                  <Col className={'simple-tile '}>
                    <div className={'width-100'}>+ {suchkriterien.arbeitsort.umkreis} km</div>
                  </Col>
                </Row>
                <div className="padding-15"></div>

                {/*SO KANN ICH ARBEITEN*/}
                {suchkriterien.auswahlArbeitsverhaeltnis && (
                  <Row>
                    <Col className={'no-padding'}>
                      <h6>SO KANN ICH ARBEITEN</h6>
                    </Col>
                  </Row>
                )}
                <div className="padding-5"></div>
                <Row className={'gap-15'}>
                  {suchkriterien.auswahlArbeitsverhaeltnis?.map((arbeitsverhaeltnis, index) => (
                    <Col className={'simple-tile '}>
                      <div className={'width-100'} key={index}>
                        {Arbeitsverhaeltnis[arbeitsverhaeltnis]}
                      </div>
                    </Col>
                  ))}
                </Row>

                <div className="padding-15"></div>

                {/* Mehrtägige Montageeinsätze*/}
                <Row>
                  <Col className={'no-padding'}>
                    <h6>MEHRTÄGIGE MONTAGEEINSÄTZE</h6>
                  </Col>
                </Row>
                <div className="padding-5"></div>
                <Row>
                  <Col sm="6" xs="6" className={'simple-tile '}>
                    <div className={'width-100'}>{suchkriterien.auswahlMontage}</div>
                  </Col>
                </Row>

                <div className="padding-15"></div>

                <Row className="gap-15">
                  <Col className={'no-padding'}>
                    <div className={'workflow-button-max w-button'} onClick={() => handleRecommendationDeclined(recommendation)}>
                      NICHT INTERESSANT
                    </div>
                  </Col>
                  <Col className={'no-padding'}>
                    <div className={'call-to-action-max w-button'} onClick={() => navigate('/suche/arbeitgeber/angebot/' + id)}>
                      EINLADUNG SENDEN
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SucheDetailsArbeitgeberDesktop;
