import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getMatchesForArbeitnehmer } from 'app/shared/reducers/match.reducer';
import { IMatch } from 'app/shared/model/match.model';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { getArbeitgeberByIds } from 'app/shared/reducers/arbeitgeber.reducer';
import { getArbeitnehmerSucheByIds } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { getDateByTimestamp } from 'app/shared/util/date-utils';

export const MatchArbeitnehmer = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const matchesList: IMatch[] = useAppSelector(state => state.match.entities);
  const arbeitgeberListe: IArbeitgeber[] = useAppSelector(state => state.arbeitgeber.entities);
  const arbeitnehmersucheListe: IArbeitnehmersuche[] = useAppSelector(state => state.arbeitnehmersuche.entities);

  useEffect(() => {
    dispatch(getMatchesForArbeitnehmer(account.id));
  }, []);

  useEffect(() => {
    if (matchesList.length > 0) {
      const arbeitgeberIds = matchesList.map(match => match.arbeitgeberId);
      const arbeitnehmersucheIds = matchesList.map(match => match.arbeitnehmerSucheId);

      dispatch(getArbeitgeberByIds(arbeitgeberIds));
      dispatch(getArbeitnehmerSucheByIds(arbeitnehmersucheIds));
    }
  }, [matchesList]);

  function selectArbeitgeber(arbeitgeberId: string) {
    return arbeitgeberListe.find(arbeitgeber => arbeitgeber.userID === arbeitgeberId);
  }

  function selectArbeitnehmersuche(arbeitnehmersucheId: string) {
    return arbeitnehmersucheListe.find(arbeitnehmersuche => arbeitnehmersuche.id === arbeitnehmersucheId);
  }

  function loaded() {
    return arbeitgeberListe.length > 0 && arbeitnehmersucheListe.length > 0;
  }

  return (
    <div>
      {loaded() ? (
        matchesList.map((match, index) => (
          <section key={index}>
            <div className="container-std w-container">
              <div className="padding-30"></div>
              <div className="w-layout-grid grid-333">
                <Link
                  id="w-node-c13b9c4a-101e-4144-7a6b-dcb2109add4f-f5591fab"
                  to={'/matches/arbeitnehmer/' + match.id}
                  className="card-matches w-inline-block"
                >
                  <h1 className="card-heading-copy">{selectArbeitgeber(match.arbeitgeberId).unternehmensname}</h1>
                  <div className="padding-5"></div>
                  <div className="text-block-card">{selectArbeitnehmersuche(match.arbeitnehmerSucheId).auswahlBeruf}</div>
                  <div className="padding-5"></div>
                  <div className="text-block-card-small">Match am {getDateByTimestamp(match.created)}</div>
                </Link>
              </div>
              <div className="padding-20"></div>
            </div>
          </section>
        ))
      ) : (
        <section className="container-std w-container">
          <div className="flex-content-cust card-edit-dark-grey-735px-1">
            <div>
              <h1 className="heading-3">Noch keine Matches vorhanden</h1>
              <p className="paragraph-std">
                Sie haben noch keine Matches, aber das kann sich schnell ändern!
                <br />
              </p>
            </div>
            <div className="padding-5"></div>
            <div>
              <p className="paragraph-std">
                Fangen Sie jetzt an nach potentiellen Arbeitgebern zu suchen. Lassen Sie sich finden, indem Sie ihr Suchprofil
                vervollständigen falls noch nicht geschehen.
              </p>
            </div>

            <div id="8-single-select">
              <Link to={`/suche/arbeitnehmer`} className="call-to-action-max w-button">
                jetzt Suchen
              </Link>
            </div>
            <div className="padding-10"></div>
          </div>
        </section>
      )}
    </div>
  );
};

export default MatchArbeitnehmer;
