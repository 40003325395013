import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntitiesByUserID } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { SucheArbeitgeberProfilCard } from 'app/modules/suche/arbeitgeber/fragments/suche-arbeitgeber-profil-card';
import { toast } from 'react-toastify';

export const SuchProfileArbeitgeber = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const arbeitnehmersucheList: IArbeitnehmersuche[] = useAppSelector(state => state.arbeitnehmersuche.entities);

  useEffect(() => {
    dispatch(getEntitiesByUserID(account.id));
  }, []);

  function deleteProfile(id: string) {
    dispatch(deleteEntity(id))
      .then(() => toast.info('Profil erfolgreich gelöscht'))
      .then(() => dispatch(getEntitiesByUserID(account.id)));
  }

  return (
    <div>
      {arbeitnehmersucheList && arbeitnehmersucheList.length > 0 ? (
        <section>
          <div className="container-std w-container">
            <div className="padding-20"></div>
            <div className="padding-20"></div>
            <div className="text-block-25">
              <strong className="bold-text-6">Bitte wähle das gewünschte Suchprofil</strong>
            </div>
            <div className="padding-10"></div>
            <div>
              {arbeitnehmersucheList.map((suchprofil, index) => (
                <SucheArbeitgeberProfilCard deleteProfile={deleteProfile} arbeitnehmersuche={suchprofil} index={index} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div className="container-std w-container">
            <div className="padding-20"></div>
            <div className="padding-20"></div>
            <h1 className="heading-1">Herzlich Willkommen auf Deinem Dashboard</h1>
            <div className="padding-20"></div>
            <div className="text-block-25-copy">
              Lege mit einem Klick auf das grüne Plus Dein erstes Suchprofil an und <br />
              finde die passenden Kandidaten für Dein Unternehmen!
              <br />
            </div>
            <div className="padding-20"></div>
          </div>
        </section>
      )}
      <div className="container-std">
        <Link to="/suche/arbeitgeber/profiles/update" className="link-block-10 w-inline-block fl-button">
          <img
            src="../../../../content/images/plus_1plus.png"
            loading="lazy"
            alt="Ein Symbol für Hinzufügen, dargestellt durch ein Pluszeichen. Das Symbol symbolisiert die Aktion, etwas hinzuzufügen, einzufügen oder zu erweitern."
            className="image-16"
          />
        </Link>
        <div className="padding-20"></div>
      </div>
    </div>
  );
};
