import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { FormGroup, Input, Label } from 'reactstrap';
import { translate } from 'react-jhipster';
import { FlendlyProgressBar } from 'app/modules/register/progressbar';
import { toast } from 'react-toastify';
import { ArbeitnehmerEigenschaftenAuswahl } from 'app/shared/components/arbeitgeber/arbeitgeberAuswahl';
import { BerufAuswahlMultiSelect } from 'app/shared/components/berufsauswahl-multiselect';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { createEntityFromInterview, sendAnonymousInterviewStatistics } from 'app/shared/reducers/arbeitnehmer.reducer';

import { getAccount } from 'app/shared/reducers/authentication';
import { ArbeitgeberEigenschaften } from 'app/shared/components/arbeitgeber-eigenschaften-auswahl';
import { FuehrerscheinAuswahl } from 'app/shared/components/license-auswahl';
import { ArbeitsortAuswahl } from 'app/shared/components/arbeitsort-auswahl';
import { MontageAuswahl } from 'app/shared/components/montage-auswahl';
import { GehaltAuswahl } from 'app/shared/components/gehalt-auswahl';
import { MontageArbeitnehmer } from 'app/shared/model/enumerations/montage.model';
import { defaultInterview, IInterviewArbeitnehmer } from 'app/shared/model/interview-arbeitnehmer.model';
import { getRegisterUrl } from 'app/shared/util/url-utils';
import axios from 'axios';

// Define your steps as an array of strings
const steps = [
  'Beruf',
  // 'BlockedUnternehmen',
  'ArbeitgeberEigenschaften',
  'ArbeitnehmerEigenschaften',
  /*'Sprachen',*/
  'Fuehrerschein',
  'Arbeitsort',
  'Montage',
  'Gehalt',
  'Kontaktdaten',
];

export const InitialInterviewArbeitnehmer = () => {
  const dispatch = useAppDispatch();

  // steps
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps] = useState(8);

  const [interview, setInterview] = useState<IInterviewArbeitnehmer>(defaultInterview);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    const handleBeforeUnload = event => {
      dispatch(sendAnonymousInterviewStatistics(currentStep + 1));
      event.preventDefault();
      event.returnValue = '';
    };

    // Füge den EventListener nur hinzu, wenn es der letzte Schritt ist
    if (currentStep < totalSteps - 1) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      // Entferne den EventListener, egal in welchem Schritt, um Speicherlecks zu verhindern
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // currentStep als Dependency hinzufügen, damit der Effekt erneut ausgeführt wird, wenn sich der Schritt ändert
  }, [currentStep]);

  const goToNextStep = () => {
    if (isCurrentStepValid) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.error(getValidationMessage());
    }
    window.scrollTo(0, 0);
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  // Funktion um E-Mail asynchron zu überprüfen und Interview abzuschließen
  async function finishInterview() {
    let validationMessage = validateLastStep();

    if (validationMessage == null) {
      const emailExists = await checkIfEmailExists(interview.arbeitnehmer.email);

      if (emailExists) {
        toast.error('Diese Email existiert bereits. Bitte wähle eine andere Email-Adresse.');
      } else {
        dispatch(createEntityFromInterview(interview))
          .then(() => {
            dispatch(getAccount());
            window.location.href = getRegisterUrl() + '&email=' + encodeURIComponent(interview.arbeitnehmer.email);
          })
          .catch(error => {
            console.error('Error creating entities:', error);
            toast.error('Error creating entities', error);
          });
      }
    } else {
      toast.error(validationMessage);
    }
  }

  function isUserOlderThan14(geburtsdatum) {
    return new Date(geburtsdatum).getFullYear() < new Date().getFullYear() - 14;
  }

  function validateLastStep(): string | null {
    if (
      interview.arbeitnehmer.auswahlAnrede === null ||
      interview.arbeitnehmer.vorname === null ||
      interview.arbeitnehmer.name === null ||
      interview.arbeitnehmer.email === null ||
      interview.arbeitnehmer.telefon === null
    ) {
      return 'Bitte fülle alle Pflichtfelder Deiner Kontaktdaten aus';
    }

    if (interview.arbeitnehmer.geburtsdatum == null) {
      return 'Bitte geb ein Geburtstdatum ein.';
    }

    if (!isUserOlderThan14(interview.arbeitnehmer.geburtsdatum)) {
      return 'Dein Geburtstdatum muss mindestens 14 Jahre zurückliegen.';
    }

    return null;
  }

  async function checkIfEmailExists(email: string): Promise<boolean> {
    let url = 'api/arbeitnehmers/exists/' + email;
    try {
      const response = await axios.get(url);
      return (await response.data) === true;
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
      return false; // Fehlerfall: Annahme zurückgeben, dass die E-Mail nicht existiert
    }
  }

  const validateStep = stepNumber => {
    switch (stepNumber) {
      case 'ArbeitnehmerEigenschaften':
        return interview.suchkriterien.auswahlEigenschaften.length > 0;
      case 'Beruf':
        if (interview.suchkriterien.auswahlBeruf != null && interview.suchkriterien.auswahlBeruf.auswahlBeruf === 'MALER_UND_LACKIERER') {
          return (
            interview.suchkriterien.auswahlBeruf.auswahlBerufserfahrung != null &&
            interview.suchkriterien.auswahlBeruf.auswahlAusbildungsstand != null
          );
        } else {
          return (
            interview.suchkriterien.auswahlBeruf &&
            interview.suchkriterien.auswahlBeruf.auswahlBeruf != null &&
            interview.suchkriterien.auswahlBeruf.auswahlBerufserfahrung != null &&
            interview.suchkriterien.auswahlBeruf.auswahlAusbildungsstand != null
          );
        }
      case 'BlockedUnternehmen':
        return true;
      case 'ArbeitgeberEigenschaften':
        return interview.suchkriterien.auswahlEigenschaften.length > 0;
      /*case 'Sprachen':
        return interview.suchkriterien.auswahlSprache.length > 0;*/
      case 'Fuehrerschein':
        return interview.suchkriterien.auswahlLicense.length > 0;
      case 'Arbeitsort':
        return (
          interview.suchkriterien.arbeitsort !== null &&
          ((interview.suchkriterien.arbeitsort.flexibel === false && interview.suchkriterien.arbeitsort.arbeitsort.length === 5) ||
            interview.suchkriterien.arbeitsort.flexibel)
        );
      case 'Montage':
        return interview.suchkriterien.auswahlMontage.length > 0;
      case 'Gehalt':
        return interview.suchkriterien.auswahlGehalt != null
          ? interview.suchkriterien.auswahlGehalt !== '' && interview.suchkriterien.auswahlGehalt.match(/(^\d{2,4}$)/) != null
          : false;
      default:
        return false;
    }
  };

  // Funktionen zum Vor- und Zurückgehen zwischen den Schritten und validieren der Schritte
  const getValidationMessage = () => {
    let validationMessage = '';
    switch (steps[currentStep]) {
      case 'Kontaktdaten':
        validationMessage = 'Bitte fülle alle Pflichtfelder Deiner Kontaktdaten aus und geb ein Geburtstdatum an';
        return validationMessage;
      case 'ArbeitnehmerEigenschaften':
        validationMessage = 'Bitte wähle mindestens eine Eigenschaft aus';
        return validationMessage;
      case 'Beruf':
        validationMessage = 'Bitte fülle alle Pflichtfelder aus';
        return validationMessage;
      case 'BlockedUnternehmen':
        validationMessage = 'Bitte wähle mindestens ein Unternehmen aus';
        return validationMessage;
      case 'ArbeitgeberEigenschaften':
        validationMessage = 'Bitte wähle mindestens eine Eigenschaft aus';
        return validationMessage;
      /*case 'Sprachen':
        validationMessage = 'Bitte wähle mindestens eine Sprache aus';
        return validationMessage;*/
      case 'Fuehrerschein':
        validationMessage = 'Bitte wähle mindestens einen Führerschein aus';
        return validationMessage;
      case 'Arbeitsort':
        validationMessage = 'Bitte wähle mindestens einen Arbeitsort aus';
        return validationMessage;
      case 'Montage':
        validationMessage = 'Bitte geb Deine Montagebereitschaft an';
        return validationMessage;
      case 'Gehalt':
        validationMessage = 'Bitte geb ein realistisches Wunschgehalt ein.';
        return validationMessage;
      default:
        return null;
    }
  };

  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);

  useEffect(() => {
    setIsCurrentStepValid(validateStep(steps[currentStep]));
  }, [currentStep, interview]);

  function setInterviewSuchkriterienValues(field: string, value: any[]) {
    setInterview(prev => {
      return { ...prev, suchkriterien: { ...prev.suchkriterien, [field]: value } };
    });
  }

  function setInterviewArbeitnehmerValues(field: string, value: any) {
    setInterview(prev => {
      return { ...prev, arbeitnehmer: { ...prev.arbeitnehmer, [field]: value } };
    });
  }

  const renderCurrentStep = () => {
    switch (currentStep) {
      // START ARBEITNEHMER
      case steps.indexOf('Kontaktdaten'):
        return (
          <div>
            <FormGroup className="form-group-full">
              <div className="wrapper-460px">
                <Label for="sprachenSelect" className="field-label-std">
                  Anrede
                </Label>
                <Input
                  className="text-field-45px w-input"
                  id="sprachenSelect"
                  placeholder="Bitte wähle eine Anrede"
                  name="select"
                  type="select"
                  onChange={e => setInterviewArbeitnehmerValues('auswahlAnrede', e.target.value)}
                  value={interview.arbeitnehmer.auswahlAnrede}
                  style={{
                    color: interview.arbeitnehmer.auswahlAnrede ? 'black' : 'gray',
                  }}
                >
                  <option value="" disabled selected hidden>
                    Bitte wähle eine Anrede
                  </option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </Input>
              </div>
            </FormGroup>
            <FlendlyValidateField
              label={translate('flendlyApp.arbeitnehmer.vorname')}
              id="arbeitnehmer-vorname"
              name="vorname"
              data-cy="vorname"
              value={interview.arbeitnehmer.vorname}
              onChange={e => setInterviewArbeitnehmerValues('vorname', e.target.value)}
            />
            <FlendlyValidateField
              label="Nachname"
              id="arbeitnehmer-name"
              name="name"
              data-cy="name"
              onChange={e => setInterviewArbeitnehmerValues('name', e.target.value)}
              value={interview.arbeitnehmer.name}
            />
            <FlendlyValidateField
              label={translate('flendlyApp.arbeitnehmer.telefon')}
              id="arbeitnehmer-telefon"
              name="telefon"
              data-cy="telefon"
              onChange={e => setInterviewArbeitnehmerValues('telefon', e.target.value)}
              value={interview.arbeitnehmer.telefon}
            />

            <FlendlyValidateField
              label="email"
              id="arbeitnehmer-email"
              name="email"
              data-cy="telefon"
              onChange={e => setInterviewArbeitnehmerValues('email', e.target.value)}
              value={interview.arbeitnehmer.email}
            />

            <FlendlyValidateField
              label="Geburtsdatum (mind. 14 Jahre)"
              id="arbeitnehmer-geburtsdatum"
              name="geburtsdatum"
              data-cy="geburtsdatum"
              type="date"
              onChange={e => setInterviewArbeitnehmerValues('geburtsdatum', e.target.value)}
              value={interview.arbeitnehmer.geburtsdatum}
            />
          </div>
        );
      case steps.indexOf('ArbeitnehmerEigenschaften'):
        return (
          <div>
            <ArbeitnehmerEigenschaftenAuswahl
              title="Welche Eigenschaften beschreiben Dich am besten? (max. 4 Punkte)"
              maxSelect={4}
              onAuswahlChange={value => setInterviewArbeitnehmerValues('auswahlArbeitnehmerEigenschaften', value)}
              initialValue={interview.arbeitnehmer.auswahlArbeitnehmerEigenschaften}
            />
          </div>
        );
      case steps.indexOf('Beruf'):
        // berufe
        return (
          <div>
            <BerufAuswahlMultiSelect
              formEnabled={true}
              onAuswahlChange={value => setInterviewSuchkriterienValues('auswahlBeruf', value)}
              initialValue={interview.suchkriterien.auswahlBeruf}
            />
          </div>
        );
      case steps.indexOf('ArbeitgeberEigenschaften'):
        return (
          <div>
            <ArbeitgeberEigenschaften
              title="Was ist Dir bei einem Arbeitgeber wichtig? (max. 8 Punkte)"
              onAuswahlChange={value => setInterviewSuchkriterienValues('auswahlEigenschaften', value)}
              initialValue={interview.suchkriterien.auswahlEigenschaften}
            />
          </div>
        );
      /*case steps.indexOf('Sprachen'):
        return (
          <div>
            <SprachenAuswahl
              title="Welche Sprache(n) sprechen Sie?"
              onAuswahlChange={value => setInterviewSuchkriterienValues('auswahlSprache', value)}
              initialValue={interview.suchkriterien.auswahlSprache}
            />
          </div>
        );*/
      case steps.indexOf('Fuehrerschein'):
        return (
          <div>
            <FuehrerscheinAuswahl
              title={'Welche Führerscheine besitzt Du?'}
              onAuswahlChange={value => setInterviewSuchkriterienValues('auswahlLicense', value)}
              initialValue={interview.suchkriterien.auswahlLicense}
            />
          </div>
        );
      case steps.indexOf('Arbeitsort'):
        return (
          <div>
            <ArbeitsortAuswahl
              title={'Wo möchtest Du arbeiten?'}
              onAuswahlChange={value => setInterviewSuchkriterienValues('arbeitsort', value)}
              initialValue={interview.suchkriterien.arbeitsort}
            />
          </div>
        );
      case steps.indexOf('Montage'):
        return (
          <div>
            <MontageAuswahl
              title={'Bist Du zu Montageeinsätzen bereit?'}
              montageAuswahl={Object.values(MontageArbeitnehmer)}
              onAuswahlChange={value => setInterviewSuchkriterienValues('auswahlMontage', value)}
              initialValue={interview.suchkriterien.auswahlMontage}
            />
          </div>
        );
      case steps.indexOf('Gehalt'):
        return (
          <GehaltAuswahl
            label={'Mind. Monatsgehalt (Brutto)'}
            title={'Wie viel möchtest Du mindestens verdienen?'}
            wrapperClass={'wrapper-480'}
            onAuswahlChange={value => setInterviewSuchkriterienValues('auswahlGehalt', value)}
            initialValue={interview.suchkriterien.auswahlGehalt}
          />
        );
      default:
        return;
    }
  };

  return (
    <div>
      <div className="section-workflow-progress-bar">
        <FlendlyProgressBar cssClassSteps={'grid-progress-bar-an'} totalSteps={totalSteps} currentStep={currentStep} />
      </div>
      <div className="section-workflow-steps">
        <div className="container-std w-container">
          <div className="w-form">
            <div className="card-edit-body">{renderCurrentStep()}</div>

            <div className="padding-30" />

            <div className={currentStep > 0 ? 'flendly-button-bar' : 'flendly-button-bar-end'}>
              {currentStep > 0 && (
                <button
                  className="button-workflow-forward w-node-c34aae07-d4ae-83b6-6df1-74d77b4e246a-1e4981b7 w-button"
                  onClick={goToPreviousStep}
                  disabled={currentStep === 0}
                >
                  Zurück
                </button>
              )}

              {currentStep === totalSteps - 1 && (
                <button className="call-to-action-max w-button" onClick={finishInterview}>
                  Weiter
                </button>
              )}

              {/* Dirty hack, should be refactored */}
              {currentStep < totalSteps - 1 && (
                <div>
                  <div className="d-inline-block" data-toggle="popover" data-content="Disabled popover">
                    <button
                      type="button"
                      id="buttonweiter"
                      className="button-workflow-forward w-button"
                      onClick={goToNextStep}
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                    >
                      Weiter
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialInterviewArbeitnehmer;
