import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { IRecommendation } from 'app/shared/model/recommendation.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { getAgeByDateOfBirth } from 'app/shared/util/date-utils';
import { SucheArbeitgeberFilters } from 'app/modules/suche/arbeitgeber/fragments/suche-arbeitgeber-filters';
import { MdAccessTime, MdEuro, MdSchool } from 'react-icons/md';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';

interface SucheArbeitgeberProps {
  arbeitnehmersuche: IArbeitnehmersuche;
  id: string;
  recommendation: IRecommendation;
  handleRecommendationDeclined: (value: IRecommendation) => void;
  arbeitnehmer: IArbeitnehmer;
  suchkriterien: ISuchkriterien;
}

export const SucheArbeitgeberMobil: React.FC<SucheArbeitgeberProps> = ({
  arbeitnehmersuche,
  id,
  recommendation,
  handleRecommendationDeclined,
  arbeitnehmer,
  suchkriterien,
}) => {
  const navigate = useNavigate();

  return (
    <Row className="d-md-none">
      <Col md={{ size: 6, offset: 3 }}>
        <Card style={{ backgroundColor: '#dbe3ed', margin: '15px' }}>
          <Link to={'/suche/arbeitgeber/details/' + recommendation.id} className="link-block-company-cards w-inline-block"></Link>
          <CardBody className={'mobile-tile'}>
            <h3>
              <b>
                {arbeitnehmer.vorname}, {getAgeByDateOfBirth(arbeitnehmer.geburtsdatum)}
              </b>
            </h3>
            <h4>{Berufe[suchkriterien.auswahlBeruf?.auswahlBeruf]}</h4>
            {arbeitnehmer.profilePictureUrl ? (
              <img src={arbeitnehmer.profilePictureUrl} alt="Profilbild" className="rounded-circle" />
            ) : (
              <img src="../../../../../content/images/portrait-placeholder.jpg" alt="Profilbild" className="rounded-circle" />
            )}
            <h6>MEINE AUSBILDUNG</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              <div className={'simple-tile'}>
                <div className={'mobile-icon'}>
                  <MdSchool />
                </div>
                <div className={'width-100'}>
                  <b>{suchkriterien.auswahlBeruf?.auswahlAusbildungsstand}</b>
                </div>
              </div>
              <div className={'simple-tile'}>
                <div className={'mobile-icon'}>
                  <MdAccessTime />
                </div>
                <div className={'width-100'}> {suchkriterien.auswahlBeruf?.auswahlBerufserfahrung}</div>
              </div>
              <div className={'simple-tile'}>
                <div className={'mobile-icon'}>
                  <MdEuro />
                </div>
                <div className={'width-100'}>
                  {'>'} {suchkriterien.auswahlGehalt}€
                </div>
              </div>
            </div>
            <div className="padding-15" />
            <h6>ICH BIN</h6>
            <div style={{ width: '100%' }} className={'simple-tile-column'}>
              {arbeitnehmer.auswahlArbeitnehmerEigenschaften.map(value => (
                <li className={'simple-tile'}>{value}</li>
              ))}
            </div>
            <Row style={{ width: '100%' }} className={'gap-15'}>
              <Col className={'no-padding'}>
                <div className={'workflow-button-max w-button'} onClick={() => handleRecommendationDeclined(recommendation)}>
                  NICHT INTERESSANT
                </div>
              </Col>
              <Col className={'no-padding'}>
                <div className={'call-to-action-max w-button'} onClick={() => navigate('/suche/arbeitgeber/angebot/' + recommendation.id)}>
                  EINLADUNG SENDEN
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div style={{ margin: '15px' }}>
          <SucheArbeitgeberFilters id={id} arbeitnehmersuche={arbeitnehmersuche} />
        </div>
      </Col>
    </Row>
  );
};
