import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { INotification, NotificationType } from 'app/shared/model/notification.model';
import { Angebot } from 'app/modules/notifications/angebot';
import { AngebotAccepted } from 'app/modules/notifications/angebot-accepted';
import { AngebotRejected } from 'app/modules/notifications/angebot-rejected';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Anfrage } from 'app/modules/notifications/anfrage';
import { AnfrageRejected } from 'app/modules/notifications/anfrage-abgelehnt';
import { setProcessing } from 'app/shared/reducers/notification.reducer';
import { EmailChanged } from 'app/modules/notifications/email-changed';
import { PasswordChanged } from 'app/modules/notifications/password-changed';
import { getNotifcationServicePath } from 'app/shared/util/url-utils';

export const NotificationComponent = () => {
  const eventSourceRef = useRef(null);
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  /*modal */
  const [modalContent, setModalContent] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  function closeModal() {
    setModal(false);
  }

  const isProcessing = useAppSelector(state => state.notifications.isProcessing);

  const closeEvent = () => {
    setModal(false);
    setNotificationQueue(notificationQueue.filter(n => n.id !== notification?.id));
    setNotification(null);
  };

  /*notification queue*/
  const [notificationQueue, setNotificationQueue] = useState<INotification[]>([]);
  const [notification, setNotification] = useState<INotification | null>(null);

  useEffect(() => {
    if (isProcessing) {
      setModal(true);
    }
    if (!isProcessing && notification) {
      closeEvent();
    }
  }, [isProcessing]);

  useEffect(() => {
    if (notification) {
      handleNotification(notification);
    }
  }, [notification]);

  useEffect(() => {
    if (notificationQueue.length > 0) {
      if (!notification) {
        setNotification(notificationQueue[0]);
      }
    }
  }, [notificationQueue]);

  useEffect(() => {
    if (account?.id) {
      const url = getNotifcationServicePath();
      const eventSource = new EventSource(url + account?.id);

      eventSource.addEventListener('notification', event => {
        const notifications = JSON.parse(event.data);

        if (!isProcessing && notifications && notifications['count'] > 0) {
          setNotificationQueue(prevQueue => [...prevQueue, ...notifications['notifications']]);
        }
      });

      eventSource.onerror = error => {
        if (eventSource.readyState !== EventSource.OPEN) {
          console.log('Verbindung verloren. Versuche, neu zu verbinden...');
          eventSource.close();
          setTimeout(connect, 5000); // Versucht, sich nach 5 Sekunden neu zu verbinden
        } else {
          console.log(error);
          toast.error('Fehler beim Empfangen der Nachrichten: ' + error);
        }
      };

      eventSourceRef.current = eventSource;
    }
  }, [account?.id]);

  const connect = () => {
    if (account && account?.id && account?.id !== '' && !eventSourceRef.current) {
      const url = getNotifcationServicePath();
      const eventSource = new EventSource(url + account?.id);

      eventSourceRef.current = eventSource;
    }
  };

  function handleNotification(notification: INotification) {
    switch (notification.type) {
      /*Angebot von Arbeitgeber an Arbeitnehmer*/
      case NotificationType.ANGEBOT:
        setModalContent(<Angebot notification={notification} closeModal={closeModal} />);
        break;
      case NotificationType.ANGEBOT_ACCEPTED:
        setModalContent(<AngebotAccepted notification={notification} closeModal={closeModal} />);
        break;
      case NotificationType.ANGEBOT_REJECTED:
        setModalContent(<AngebotRejected notification={notification} closeModal={closeModal} />);
        break;
      /*Anfrage von Arbeitnehmer an Arbeitgeber*/
      case NotificationType.ANFRAGE:
        setModalContent(<Anfrage notification={notification} closeModal={closeModal} />);
        break;
      case NotificationType.ANFRAGE_REJECTED:
        setModalContent(<AnfrageRejected notification={notification} closeModal={closeModal} />);
        break;
      case NotificationType.EMAIL_CHANGED:
        setModalContent(<EmailChanged notification={notification} closeModal={closeModal} />);
        break;
      case NotificationType.PASSWORD_CHANGED:
        setModalContent(<PasswordChanged notification={notification} closeModal={closeModal} />);
        break;
      default:
        console.log('Unknown notification type: ' + notification.type);
        break;
    }
    dispatch(setProcessing(notification));
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
    </div>
  );
};

export default NotificationComponent;
