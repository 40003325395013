import { Link } from 'react-router-dom';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import React from 'react';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';

interface SucheArbeitgeberFilterProps {
  arbeitnehmersuche: IArbeitnehmersuche;
  id: string;
}

export const SucheArbeitgeberFilters: React.FC<SucheArbeitgeberFilterProps> = ({ arbeitnehmersuche, id }) => {
  return (
    <div>
      <div className="padding-5" />
      <div style={{ display: 'ruby' }}>
        <Link to={'/suche/arbeitgeber/profiles/update/' + id} className="link-block-13 w-inline-block">
          <img
            src="../../../../../content/images/edit2.svg"
            loading="lazy"
            id="w-node-_62efc283-2f8a-058c-5911-e86e0b813170-8d3b9927"
            alt="Ein stilisiertes Symbol für das Bearbeiten von Informationen, dargestellt durch ein Icon bestehend aus einem Stift auf Papier."
            className="image-15"
          />
        </Link>
        <div className="text-block-27">Suchprofil</div>
        <div className="text-block-filter">{Berufe[arbeitnehmersuche.auswahlBeruf]}</div>
        <div className="text-block-filter">
          {arbeitnehmersuche.auswahlAusbildungsstand.map(value => Ausbildungsstand[value] + ' ').join(' | ')}
        </div>
        <div className="text-block-filter">Quereinsteiger</div>
        <div className="text-block-filter">{arbeitnehmersuche.standort.arbeitsort}</div>
        <div className="text-block-filter">
          {arbeitnehmersuche.auswahlArbeitsverhaeltnis.map(value => Arbeitsverhaeltnis[value]).join(' | ')}
        </div>
        <div className="text-block-filter">max. {arbeitnehmersuche.gehalt}€/Monat</div>
      </div>
    </div>
  );
};
