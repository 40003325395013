import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSuchkriterienByUserId as getSuchkriterien } from 'app/shared/reducers/suchkriterien.reducer';
import { updateEntity as updateSuchkriterien } from 'app/shared/reducers/suchkriterien.reducer';
import { FormGroup, Input, Label } from 'reactstrap';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { translate } from 'react-jhipster';
import { ArbeitnehmerEigenschaftenAuswahl } from 'app/shared/components/arbeitgeber/arbeitgeberAuswahl';
import { BerufAuswahlMultiSelect } from 'app/shared/components/berufsauswahl-multiselect';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { toast } from 'react-toastify';
import { getArbeitnehmer, updateEntity as updateEntityLocal } from 'app/shared/reducers/arbeitnehmer.reducer';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { ZeitpunktAuswahl } from 'app/shared/components/zeitpunkt-auswahl';
import { ArbeitgeberEigenschaften } from 'app/shared/components/arbeitgeber-eigenschaften-auswahl';
import { SprachenAuswahl } from 'app/shared/components/language-auswahl';
import { FuehrerscheinAuswahl } from 'app/shared/components/license-auswahl';
import { ArbeitsortAuswahl } from 'app/shared/components/arbeitsort-auswahl';
import { MontageAuswahl } from 'app/shared/components/montage-auswahl';
import { GehaltAuswahl } from 'app/shared/components/gehalt-auswahl';
import { MontageArbeitnehmer } from 'app/shared/model/enumerations/montage.model';
import ProfileImageUploader from 'app/shared/components/profile-picture-component';

export const ArbeitnehmerUpdate = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);

  const navigate = useNavigate();

  /* Load model */
  const suchkriterien = useAppSelector(state => state.suchkriterien.entity);
  const arbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);

  const [suchkriterienChange, setSuchkriterienChange] = useState<ISuchkriterien>();
  const [arbeitnehmerChange, setArbeitnehmerChange] = useState<IArbeitnehmer>();

  useEffect(() => {
    if (!arbeitnehmer.userID) {
      dispatch(getArbeitnehmer(account.id));
    }
    if (!suchkriterien.userID) {
      dispatch(getSuchkriterien(account.id));
    }
  }, []);

  useEffect(() => {
    setSuchkriterienChange(suchkriterien);
  }, [suchkriterien]);

  useEffect(() => {
    setArbeitnehmerChange(arbeitnehmer);
  }, [arbeitnehmer]);

  function save() {
    dispatch(updateEntityLocal(arbeitnehmerChange))
      .then(() => {
        dispatch(updateSuchkriterien(suchkriterienChange));
      })
      .then(() => toast.success('Arbeitnehmer update successfully.'))
      .then(() => navigate('/'))
      .catch(error => {
        console.error('Error creating entities:', error);
        toast.error('Error creating entities', error);
      });
  }

  function updateEntity(feld: string, value: string) {
    setArbeitnehmerChange({ ...arbeitnehmerChange, [feld]: value });
  }

  function updateSuchkriterienLocal({ feld, value }: { feld: string; value: any }) {
    setSuchkriterienChange({ ...suchkriterienChange, [feld]: value });
  }

  return (
    //render das nur wenn arbeitnehmer und suchkriterien geladen sind
    arbeitnehmerChange &&
    suchkriterienChange && (
      <div>
        <div>
          <div className="container-std w-container">
            <div className="wrapper-735px-profile">
              <div className="card-edit-dark-grey">
                <div className="form-block-9 w-form">
                  <ProfileImageUploader />
                  <div className="divider" />
                  <div className="div-block-workflow">
                    <GehaltAuswahl
                      label={'Mind. Monatsgehalt (Brutto)'}
                      title={'Wie viel möchtest Du mindestens verdienen?'}
                      wrapperClass={'wrapper-480'}
                      onAuswahlChange={value =>
                        updateSuchkriterienLocal({
                          feld: 'auswahlGehalt',
                          value: value,
                        })
                      }
                      initialValue={suchkriterien.auswahlGehalt}
                    />
                  </div>

                  <div className="divider" />
                  <p className="paragraph-workflow">
                    <strong>In welchem Beruf möchten Sie arbeiten?</strong>
                  </p>
                  <div className="div-block-workflow">
                    <BerufAuswahlMultiSelect
                      onAuswahlChange={value =>
                        updateSuchkriterienLocal({
                          feld: 'auswahlBeruf',
                          value: value,
                        })
                      }
                      initialValue={suchkriterien.auswahlBeruf}
                    />
                  </div>

                  <div className="divider" />
                  <div className="div-block-workflow">
                    <ArbeitnehmerEigenschaftenAuswahl
                      title="Welche Eigenschaften beschreiben Dich am besten? (max. 4 Punkte)"
                      maxSelect={4}
                      onAuswahlChange={e => updateEntity('auswahlArbeitnehmerEigenschaften', e)}
                      initialValue={arbeitnehmerChange.auswahlArbeitnehmerEigenschaften}
                    />
                  </div>

                  <div className="divider" />
                  <div className="div-block-workflow">
                    <ArbeitgeberEigenschaften
                      title="Was ist Dir bei einem Arbeitgeber wichtig? (max. 8 Punkte)"
                      onAuswahlChange={value => updateSuchkriterienLocal({ feld: 'auswahlEigenschaften', value: value })}
                      initialValue={suchkriterien.auswahlEigenschaften}
                    />
                  </div>
                  <div className="divider" />
                  <div className="div-block-workflow">
                    <FuehrerscheinAuswahl
                      title={'Welche Führerscheine besitzen Sie?'}
                      onAuswahlChange={value => updateSuchkriterienLocal({ feld: 'auswahlLicense', value: value })}
                      initialValue={suchkriterien.auswahlLicense}
                    />
                  </div>
                  <div className="divider" />
                  <div className="div-block-workflow">
                    <GenericAuswahl
                      label="Arbeitszeitmodell"
                      minSelect={1}
                      maxSelect={4}
                      initialValue={suchkriterien.auswahlArbeitsverhaeltnis}
                      onAuswahlChange={value =>
                        updateSuchkriterienLocal({
                          feld: 'auswahlArbeitsverhaeltnis',
                          value: value,
                        })
                      }
                      col={Arbeitsverhaeltnis}
                      title={'Wie möchten Sie arbeiten?'}
                    />
                  </div>
                  <div className="divider" />
                  <div className="div-block-workflow">
                    <ArbeitsortAuswahl
                      title={'Wo möchtest Du arbeiten?'}
                      onAuswahlChange={value => updateSuchkriterienLocal({ feld: 'arbeitsort', value: value })}
                      initialValue={suchkriterien.arbeitsort}
                    />
                  </div>
                  <div className="divider" />
                  <div className="div-block-workflow">
                    <MontageAuswahl
                      title={'Sind Sie offen für Montageeinsätze?'}
                      montageAuswahl={Object.values(MontageArbeitnehmer)}
                      onAuswahlChange={value =>
                        updateSuchkriterienLocal({
                          feld: 'auswahlMontage',
                          value: value,
                        })
                      }
                      initialValue={suchkriterien.auswahlMontage}
                    />
                  </div>

                  <div className="divider" />
                  <div className="div-block-workflow">
                    <p className="paragraph-workflow">
                      <strong>Wie lauten Ihre Kontaktdaten?</strong>
                    </p>
                    <FormGroup className="form-group-full wrapper-460px">
                      <Label for="sprachenSelect" className="field-label-std">
                        Anrede
                      </Label>
                      <Input
                        className="text-field-45px w-select"
                        id="sprachenSelect"
                        placeholder="Bitte wählen Sie eine Anrede"
                        name="select"
                        type="select"
                        onChange={e => updateEntity('auswahlAnrede', e.target.value)}
                        value={arbeitnehmerChange.auswahlAnrede}
                        style={{
                          color: arbeitnehmerChange.auswahlAnrede ? 'black' : 'gray',
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Bitte wählen Sie eine Anrede
                        </option>
                        <option value="Herr">Herr</option>
                        <option value="Frau">Frau</option>
                      </Input>
                    </FormGroup>
                    <FlendlyValidateField
                      label={translate('flendlyApp.arbeitnehmer.vorname')}
                      id="arbeitnehmer-vorname"
                      name="vorname"
                      data-cy="vorname"
                      value={arbeitnehmerChange.vorname}
                      wrapperClass="wrapper-100"
                      onChange={e => updateEntity('vorname', e.target.value)}
                    />
                    <FlendlyValidateField
                      label="Nachname"
                      id="arbeitnehmer-name"
                      name="name"
                      data-cy="name"
                      wrapperClass="wrapper-100"
                      onChange={e => updateEntity('name', e.target.value)}
                      value={arbeitnehmerChange.name}
                    />

                    <FlendlyValidateField
                      label={translate('flendlyApp.arbeitnehmer.telefon')}
                      id="arbeitnehmer-telefon"
                      name="telefon"
                      data-cy="telefon"
                      wrapperClass="wrapper-100"
                      onChange={e => updateEntity('telefon', e.target.value)}
                      value={arbeitnehmerChange.telefon}
                    />

                    <FlendlyValidateField
                      label="Geburtsdatum (mind. 14 Jahre)"
                      id="arbeitnehmer-geburtsdatum"
                      name="geburtsdatum"
                      data-cy="geburtsdatum"
                      type="date"
                      wrapperClass="wrapper-100"
                      onChange={e => updateEntity('geburtsdatum', e.target.value)}
                      value={arbeitnehmerChange.geburtsdatum}
                    />
                  </div>
                  <div className="divider" />

                  <div className="padding-20" />
                  <div className="div-block-workflow">
                    <div className={'flendly-button-bar'}>
                      <Link className="button-workflow-forward w-button" to="/suche/arbeitnehmer">
                        Abbrechen
                      </Link>

                      <button className="call-to-action-max w-button" onClick={() => save()}>
                        Speichern
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ArbeitnehmerUpdate;
