import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getArbeitnehmerSuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { getRecommendationsForArbeitgeber, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { getArbeitnehmer as getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByUserId } from 'app/shared/reducers/suchkriterien.reducer';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { getAgeByDateOfBirth } from 'app/shared/util/date-utils';
import { toast } from 'react-toastify';
import { getArbeitnehmerSkillsByKey, getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SucheArbeitgeberDeaktiviert from 'app/modules/suche/arbeitgeber/fragments/suche-arbeitgeber-deaktiviert';
import { SucheArbeitgeberMobil } from 'app/modules/suche/arbeitgeber/fragments/suche-arbeitgeber-mobil';
import { SucheArbeitgeberDesktop } from 'app/modules/suche/arbeitgeber/fragments/suche-arbeitgeber-desktop';
import { SucheArbeitgeberKeineTreffer } from 'app/modules/suche/arbeitgeber/fragments/suche-arbeitgeber-keine-treffer';

export const SucheArbeitgeber = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);

  const { id } = useParams<'id'>();

  const recommendations: IRecommendation[] = useAppSelector(state => state.recommendation.entities);

  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);
  const [recommendation, setRecommendation] = React.useState<IRecommendation>(null);

  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  const [recommendationsList, setRecommendationsList] = React.useState<IRecommendation[]>([]);

  const [flyOut, setFlyOut] = useState(false);

  useEffect(() => {
    dispatch(getArbeitnehmerSuche(id));
    dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: false }));
  }, []);

  useEffect(() => {
    if (recommendations && recommendations.length > 0) {
      setRecommendationsList(recommendations);
    }
  }, [recommendations]);

  useEffect(() => {
    if (recommendationsList && recommendationsList.length > 0) {
      let iRecommendation = recommendationsList[0];
      setRecommendation(iRecommendation);
      dispatch(getArbeitnehmer(iRecommendation.arbeitnehmerId));
      dispatch(getSuchkriterienByUserId(iRecommendation.arbeitnehmerId));
    } else {
      dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: false }));
    }
  }, [recommendationsList]);

  function loaded() {
    return recommendation && arbeitnehmer && suchkriterien && arbeitnehmersuche;
  }

  function handleRecommendationDeclined(recommendation: IRecommendation) {
    // Starte die Animation, indem du `flyOut` auf true setzt
    setFlyOut(true);

    // Zeitverzögerung für den Zustand nach der Animation (bevor der Zustand aktualisiert wird)
    setTimeout(() => {
      const updatedRecommendation: IRecommendation = { ...recommendation, status: IRecommendationStatus.REJECTED };

      dispatch(updateRecommendation(updatedRecommendation))
        .then(() => {
          toast.info('Arbeitnehmer wurde abgelehnt');
        })
        .then(() => {
          let iRecommendations = recommendationsList.filter(r => r.id !== recommendation.id);
          if (iRecommendations.length > 0) {
            setRecommendationsList(iRecommendations);
            setFlyOut(false); // Zurücksetzen des Zustands für die nächste Karte
          } else {
            dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: false }));
            setRecommendation(null);
            setFlyOut(false); // Zurücksetzen des Zustands für die nächste Karte
          }
        });
    }, 500); // Die Dauer der Animation sollte mit der CSS-Animation (0.5s) übereinstimmen
  }

  return (
    <div>
      {loaded() ? (
        account.userSettings.searchDeactivated ? (
          <SucheArbeitgeberDeaktiviert />
        ) : (
          <div>
            <div className="padding-20" />

            <Container fluid>
              <SucheArbeitgeberMobil
                handleRecommendationDeclined={handleRecommendationDeclined}
                arbeitnehmer={arbeitnehmer}
                suchkriterien={suchkriterien}
                recommendation={recommendation}
                arbeitnehmersuche={arbeitnehmersuche}
                id={recommendation.id}
              />
              <SucheArbeitgeberDesktop
                handleRecommendationDeclined={handleRecommendationDeclined}
                arbeitnehmer={arbeitnehmer}
                suchkriterien={suchkriterien}
                recommendation={recommendation}
                arbeitnehmersuche={arbeitnehmersuche}
                id={recommendation.id}
              />
            </Container>
          </div>
        )
      ) : (
        <SucheArbeitgeberKeineTreffer id={id} />
      )}
    </div>
  );
};

export default SucheArbeitgeber;
